mcWeb.paymentTools = new function () {
    var $ = jQuery;
    var self = this;
    self.sveaService = new WebApiService("PaymentTools");

    self.getSveaMinimumMonthCost = function (plid, replaceText) {
        self.sveaService.GetAction("GetSveaMinimumMonthCost", { plid: plid }).then(function (cost) {
            if (cost != null && cost.length > 0) {
                var svea = $("#svea-web-pay-" + plid);

                if (replaceText != null) {
                    var html = svea.html();
                    html = html.replace("¤amount¤", cost);
                    svea.html(html);
                } else {
                    svea.find("span").text(cost);
                }
                svea.show();
            }
        });
    };
};
